import { useAuth } from "../hooks/use-auth";

export const CaptchaContainer = () => {
  const { user } = useAuth();

  if (!user) {
    return <div id="recaptcha-container" className="relative z-50"></div>;
  }

  return null;
};
