import { User } from "firebase/auth";
import { createContext } from "react";
import { ProfileModel } from "../../types/models/profile-model";

export type AuthContext =
  | {
      user: User | null;
      profile: ProfileModel | null;
      loading: boolean;
      logInWithGoogle: () => Promise<void>;
      logOut: () => Promise<void>;
      setAuthLoading: (value: boolean) => void;
    }
  | undefined;

export const AuthContext = createContext<AuthContext>(undefined);
