import { twMerge } from "tailwind-merge";
import { Link, LinkProps } from "react-router-dom";
interface ButtonPartialProps extends React.ComponentProps<"button"> {
  variant?: keyof typeof buttonVariantStyles;
  asComponent?: "button";
}

interface LinkPartialProps extends LinkProps {
  variant?: keyof typeof buttonVariantStyles;
  asComponent: "link";
}

type ButtonProps = ButtonPartialProps | LinkPartialProps;

const buttonVariantStyles = {
  outlined: "border-2 border-white text-white bg-transparent",
  primary:
    "bg-white text-base-5 disabled:bg-base-1 disabled:text-gray-light-5 hover:text-base-3 active:text-base-3",
  secondary: "bg-base-5 text-white",
} as const;

export const GoButton = ({
  children,
  variant = "primary",
  asComponent = "button",
  ...props
}: ButtonProps) => {
  const styles = twMerge(
    "inline-block bg-white p-4 rounded-[20px] h-14 font-extrabold text-base text-base-5 text-center active:-translate-y-0.5",
    buttonVariantStyles[variant],
    props.className
  );

  if (asComponent === "button") {
    return (
      <button {...(props as ButtonPartialProps)} className={styles}>
        {children}
      </button>
    );
  }

  if (asComponent === "link") {
    return (
      <Link {...(props as LinkPartialProps)} className={styles}>
        {children}
      </Link>
    );
  }
};
