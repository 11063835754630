import { useEffect, useRef, useState, useCallback } from "react";

type DialogOptions = {
  onOpen?: () => void;
  onClose?: () => void;
};

type UseDialogController = [
  React.RefObject<HTMLDialogElement>,
  {
    openDialog: () => void;
    closeDialog: () => void;
    isOpened: boolean;
  }
];

export const useDialogController = (options?: DialogOptions) => {
  const ref = useRef<HTMLDialogElement>(null);
  const [isOpened, setOpened] = useState(false);
  const [isEventsAdded, setEventsAdded] = useState(false);

  const onOpen = options?.onOpen;
  const onClose = options?.onClose;

  const openDialog = useCallback(() => setOpened(true), []);
  const closeDialog = useCallback(() => setOpened(false), []);

  const handleOpen = useCallback(() => {
    onOpen?.();
    setOpened(true);
  }, [onOpen]);

  const handleClose = useCallback(() => {
    onClose?.();
    setOpened(false);
  }, [onClose]);

  const preventCloseOnEsc = useCallback((e: Event) => e.preventDefault(), []);

  useEffect(() => {
    const modal = ref.current;
    if (!modal) return;

    if (isOpened) {
      handleOpen();
      modal.showModal();
    } else {
      modal.close();
    }
  }, [handleOpen, isOpened]);

  useEffect(() => {
    const modal = ref.current;
    if (!modal) return;

    if (!isEventsAdded) {
      modal.addEventListener("close", handleClose);
      modal.addEventListener("cancel", preventCloseOnEsc);
      setEventsAdded(true);
    }

    return () => {
      if (isEventsAdded) {
        modal.removeEventListener("close", handleClose);
        modal.removeEventListener("cancel", preventCloseOnEsc);
      }
    };
  }, [handleClose, isEventsAdded, preventCloseOnEsc]);

  return [
    ref,
    {
      openDialog,
      closeDialog,
      isOpened,
    },
  ] satisfies UseDialogController;
};
