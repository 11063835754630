import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
auth.useDeviceLanguage();

export let baseUrl = import.meta.env.VITE_API_BASE_URL;

if (import.meta.env.DEV) {
  const clientLanIP = import.meta.env.VITE_LOCAL_NETWORK_IP;
  const isLocalhost =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1";

  if (clientLanIP) {
    console.log("Using local network I from ENV: ", clientLanIP);
  } else if (!isLocalhost) {
    throw new Error(
      "You are not using localhost, please set the VITE_LOCAL_NETWORK_IP env variable"
    );
  }

  const localServicesIP = clientLanIP || "127.0.0.1";

  baseUrl = `http://${localServicesIP}:5001/go-quiz-oficial-dev/southamerica-east1/api/app`;
  connectAuthEmulator(auth, `http://${localServicesIP}:9099`);
  connectFirestoreEmulator(db, `${localServicesIP}`, 8080);

  const emulatorBadge = document.querySelector(".firebase-emulator-warning");
  if (emulatorBadge) {
    emulatorBadge!.innerHTML = "Emulator mode, do not use prod credentials";
  }
}
