import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import { OverlayDialog, OverlayDialogProps } from "./overlay-dialog";
import { GoCard } from "./go-cards";

type DialogProps = OverlayDialogProps;

export const SimpleDialog = forwardRef<HTMLDialogElement, DialogProps>(
  ({ children, className, ...props }, ref) => {
    const classes = twMerge(
      "font-medium text-paragraph",
      "text-gray-dark-1",
      "shadow-lg",
      "scale-95 open:scale-100",
      className
    );

    return (
      <OverlayDialog ref={ref} className={classes} {...props}>
        <GoCard>{children}</GoCard>
      </OverlayDialog>
    );
  }
);
