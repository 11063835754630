// TODO: Move atom and hook to another file?????
/* eslint-disable react-refresh/only-export-components */
import { motion, AnimatePresence } from "framer-motion";
import { createPortal } from "react-dom";
import { atom, useAtom } from "jotai";
import { ReactNode } from "react";

export interface BottomSheetProps {
  isOpen: boolean;
  content: ReactNode;
  options?: BottomSheetOptions;
}

export interface BottomSheetOptions {
  dismissable?: boolean;
  onClose?: (dismissed: boolean) => void;
}

export const bottomSheetAtom = atom<BottomSheetProps>({
  isOpen: false,
  content: null,
  options: {},
});

const defaultOptions: BottomSheetOptions = {
  dismissable: false,
} as const;

// verify platform to change iOS dialog format to centered one
const userAgent = navigator.userAgent;
const isIOS = /iPad|iPhone|iPod/.test(userAgent);
//

export const useBottomSheet = () => {
  const [state, setState] = useAtom(bottomSheetAtom);

  const openBottomSheet = (
    content: ReactNode,
    options?: BottomSheetOptions
  ) => {
    return setState({
      isOpen: true,
      content,
      options,
    });
  };

  const closeBottomSheet = (dismissed = false) => {
    if (state.options?.onClose) {
      state.options.onClose(dismissed);
    }
    return setState({
      isOpen: false,
      content: null,
      options: {
        ...defaultOptions,
      },
    });
  };

  return { openBottomSheet, closeBottomSheet, state };
};

export const BottomSheet = () => {
  const { state, closeBottomSheet } = useBottomSheet();

  return createPortal(
    <AnimatePresence>
      {state.isOpen && (
        <div className={
          `fixed inset-0 z-50 flex justify-center
          ${isIOS ? 'items-center' : 'items-end'}
          `}
        >
          <motion.div
            className={`fixed inset-0 bg-opacity-50 bg-gray-dark-1`}
            onClick={() => state.options?.dismissable && closeBottomSheet(true)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
          <motion.article
            className={`relative w-full max-w-md p-6 bg-white shadow-lg h-fit
                        ${isIOS ? 'm-6 rounded-lg' : 'mx-auto  rounded-t-lg'}
                        `}
            initial={{ y: "300%" }}
            animate={{ y: 0 }}
            exit={{ y: "300%" }}
            transition={{ type: "spring", damping: 25, stiffness: 300 }}
          >
            {state.content}
          </motion.article>
        </div>
      )}
    </AnimatePresence>,
    document.body
  );
};
