import React, { Suspense, useEffect } from "react";
import toast, { useToasterStore } from "react-hot-toast";

import { RouterProvider } from "react-router-dom";

import { Provider as JotaiProvider } from "jotai";
import { AuthProvider } from "./providers/auth/auth-provider";
import { BottomSheet } from "./components/bottom-sheet";
import { Dialog } from "./components/dialog";
import LoadingRoute from "./routes/loading-route";
import { CaptchaContainer } from "./components/captcha-container";
import { ErrorDialogProvider } from "./providers/error-dialog/error-dialog-provider";
import { Toaster } from "react-hot-toast";
import { router } from "./router";

export function App() {
  const { toasts } = useToasterStore();

  const TOAST_LIMIT = 3;

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  return (
    <React.StrictMode>
      <AuthProvider>
        <ErrorDialogProvider>
          <JotaiProvider>
            <Suspense fallback={<LoadingRoute />}>
              <RouterProvider router={router} />
            </Suspense>
            <BottomSheet />
            <Dialog />
          </JotaiProvider>
          <CaptchaContainer />
        </ErrorDialogProvider>
      </AuthProvider>
      <Toaster position="bottom-center" reverseOrder={false} />
    </React.StrictMode>
  );
}
