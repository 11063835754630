import {
  GoogleAuthProvider,
  User,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  unlink,
} from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import { auth, db } from "../../configs/firebase-config";
import { doc, onSnapshot } from "firebase/firestore";
import { ProfileModel } from "../../types/models/profile-model";
import { AuthContext } from "./auth-context";

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<ProfileModel | null>(null);
  const [loading, setLoading] = useState(true);

  const logInWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider);
  }, []);

  const logOut = async () => {
    const providerId = auth.currentUser?.providerData[0]?.providerId;

    if (providerId && providerId === "google.com") {
      await unlink(auth.currentUser!, providerId);
    }

    await signOut(auth);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    let unsubscribeProfile = () => {};

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(true);
      setUser(currentUser);

      if (currentUser !== null) {
        unsubscribeProfile = onSnapshot(
          doc(db, "users", currentUser.uid),
          (snapshot) => {
            setProfile(snapshot.data() as ProfileModel);
            setLoading(false);
          }
        );
      } else {
        unsubscribeProfile();
        setLoading(false);
        setProfile(null);
      }
    });

    return () => {
      unsubscribeProfile();
      unsubscribe();
    };
  }, []);

  const authValue: AuthContext = {
    user,
    loading,
    logInWithGoogle,
    profile,
    logOut,
    setAuthLoading: setLoading,
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};
