import { useCallback, useState } from "react";
import { useDialogController } from "../../hooks/use-dialog";
import { SimpleDialog } from "../../components/simple-dialog";
import { ErrorDialogContext } from "./error-dialog-context";
import { GoButton } from "../../components/go-button";

interface ErrorDialogProviderProps {
  children: React.ReactNode;
}

export const ErrorDialogProvider = ({ children }: ErrorDialogProviderProps) => {
  const [message, setMessage] = useState("");
  const [errorDialogRef, { openDialog }] = useDialogController();

  const showError = useCallback(
    (message: string) => {
      setMessage(message);
      openDialog();
    },
    [openDialog]
  );

  return (
    <ErrorDialogContext.Provider value={{ showError }}>
      {children}
      <SimpleDialog ref={errorDialogRef} backdropDismissable>
        <div>{message}</div>
        <form method="dialog">
          <GoButton variant="secondary" className="mt-4 w-full">
            Tudo bem
          </GoButton>
        </form>
      </SimpleDialog>
    </ErrorDialogContext.Provider>
  );
};
