import { useContext } from "react";
import { AuthContext } from "../providers/auth/auth-context";

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(
      "AuthContext must be used within a AuthProvider, make sure to use it as a parent component"
    );
  }

  return context;
};
