/* eslint-disable react-refresh/only-export-components */
// TODO: Move atom and hook to another file?????
import { motion, AnimatePresence } from "framer-motion";
import { atom, useAtom } from "jotai";
import { ReactNode, useCallback } from "react";
import { createPortal } from "react-dom";

interface DialogOptions {
  isOpen: boolean;
  content: ReactNode;
  dismissable: boolean;
}

export const dialogAtom = atom<DialogOptions>({
  isOpen: false,
  content: null,
  dismissable: false,
});

export const useDialog = () => {
  const [state, setState] = useAtom(dialogAtom);

  const openDialog = useCallback(
    (content: ReactNode, dismissable = false) => {
      setState({
        ...state,
        isOpen: true,
        content,
        dismissable,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const closeDialog = useCallback(() => {
    setState({
      ...state,
      isOpen: false,
      content: state.content,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ...state, openDialog, closeDialog };
};

export const Dialog = () => {
  const [state] = useAtom(dialogAtom);
  const { closeDialog } = useDialog();

  const handleDismiss = useCallback(() => {
    if (state.dismissable) {
      closeDialog();
    }
  }, [closeDialog, state.dismissable]);

  const handleStopPropagationDismis = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
    },
    []
  );

  return createPortal(
    <AnimatePresence>
      {state.isOpen && (
        <>
          <motion.div className="z-[49] fixed inset-0 bg-gray-dark-1 bg-opacity-50 w-screen" />
          <motion.div
            onClick={handleDismiss}
            className="z-50 fixed inset-0 flex justify-center items-center"
            initial={{
              scale: 0.5,
              opacity: 1,
            }}
            animate={{
              scale: 1,
              opacity: 1,
            }}
            exit={{
              scale: 0.25,
              opacity: 0,
            }}
            transition={{
              duration: 0.15,
              ease: [0, 0.75, 0.2, 1],
              type: "linear",
            }}
          >
            <div
              onClick={handleStopPropagationDismis}
              className="flex justify-center items-center"
            >
              {state.content}
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>,
    document.body
  );
};
