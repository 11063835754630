/* eslint-disable react-refresh/only-export-components */
import React from "react";
import "./index.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import * as Sentry from "@sentry/react";
import { Route } from "react-router-dom";

// Layouts
const RootLayout = React.lazy(() => import("./layout/root-layout"));

// Routes
const PreGameRoute = React.lazy(() => import("./routes/pre-game-route"));
const ProtectedRoute = React.lazy(() => import("./routes/protected-route"));
const PublicRoute = React.lazy(() => import("./routes/public-route"));

// Pages
const UpdateProfilePage = React.lazy(
  () => import("./pages/update-profile-page")
);
const MyDetailsPage = React.lazy(() => import("./pages/my-details-page"));
const UpdateProfileFieldPage = React.lazy(
  () => import("./pages/update-profile-field-page")
);
const LoginPage = React.lazy(() => import("./pages/login-page"));
const GamePage = React.lazy(() => import("./pages/game/game-page"));
const ErrorPage = React.lazy(() => import("./pages/error-page"));
const CheckoutPage = React.lazy(() => import("./pages/checkout-page"));
const DashboardPage = React.lazy(
  () => import("./pages/dashboard/dashboard-page")
);
const ShopPage = React.lazy(() => import("./pages/shop/shop-page"));
const ConfigPage = React.lazy(() => import("./pages/config/config-page"));
const PhoneConfirmationPage = React.lazy(
  () => import("./pages/phone-confirmation-page")
);
const TermsPage = React.lazy(() => import("./pages/config/terms-page"));
const FAQPage = React.lazy(() => import("./pages/config/faq-page"));
const LobbyPage = React.lazy(() => import("./pages/lobby/lobby-page"));

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      errorElement={
        <RootLayout bgVariant="circle">
          <ErrorPage />
        </RootLayout>
      }
    >
      <Route element={<RootLayout bgVariant="circle" />}>
        {/* Can be opened on both, signed in or not */}
        <Route path="terms" element={<TermsPage />} />
        <Route path="faq" element={<FAQPage />} />
      </Route>

      <Route element={<PublicRoute />}>
        <Route element={<RootLayout bgVariant="circle" />}>
          <Route index path="/" element={<LoginPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route
            path="phone-confirmation"
            element={<PhoneConfirmationPage />}
          />
        </Route>
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route element={<RootLayout />}>
          <Route path="game" element={<PreGameRoute />}>
            <Route path=":id" element={<GamePage />} />
          </Route>
          <Route index path="dashboard" element={<DashboardPage />} />
          <Route path="lobby" element={<PreGameRoute />}>
            <Route index path=":id" element={<LobbyPage />} />
          </Route>
          <Route path="checkout" element={<CheckoutPage />}></Route>
          <Route path="profile/update" element={<UpdateProfilePage />} />

          <Route path="shop" element={<ShopPage />} />
        </Route>
        <Route element={<RootLayout bgVariant="circle" />}>
          <Route path="config" element={<ConfigPage />} />
          <Route path="my-details" element={<MyDetailsPage />} />
          <Route
            path="profile/update-field"
            element={<UpdateProfileFieldPage />}
          />
        </Route>
      </Route>
    </Route>
  ),
  {
    basename: "/",
  }
);
