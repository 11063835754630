import { forwardRef } from "react";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";

export type OverlayDialogProps = React.ComponentProps<"dialog"> & {
  backdropDismissable?: boolean;
};

export const OverlayDialog = forwardRef<HTMLDialogElement, OverlayDialogProps>(
  ({ children, className, backdropDismissable, ...props }, ref) => {
    const dialogStyle = twMerge(
      "justify-center items-center grid grid-cols-1 grid-rows-1",
      "fixed w-full h-full m-0 max-h-none max-w-none p-4",
      "bg-transparent",
      "transition-all duration-200 ease-out",
      "opacity-0 open:opacity-100",
      "invisible open:visible",
      "backdrop:bg-gray-dark-1 backdrop:bg-opacity-50",
      className
    );

    const dialogBoxStyle = `
      w-fit h-fit self-center justify-self-center
      flex justify-center items-center
      col-start-1 row-start-1
    `;

    const backdropStyle = `
      grid justify-self-stretch self-stretch
      col-start-1 row-start-1 -z-[1]
      text-transparent
    `;

    return createPortal(
      <dialog ref={ref} className={dialogStyle} {...props}>
        <div className={dialogBoxStyle}>{children}</div>
        {backdropDismissable && (
          <form method="dialog" className={backdropStyle}>
            <button className="text-transparent">close</button>
          </form>
        )}
      </dialog>,
      document.body
    );
  }
);
