import { twMerge } from "tailwind-merge";

type GoCardProps = React.ComponentProps<"div">;

export const GoCard = (props: GoCardProps) => {
  return (
    <div
      {...props}
      className={twMerge(
        "bg-white shadow-card p-6 rounded-[20px] text-gray-light-8",
        props.className
      )}
    />
  );
};
